<template>
  <div class="product" v-if="product.name">
    <a class="product-link" v-if="!product.isStaticProduct">
      <div style="text-align: center">
        <VueHeadline class="product-content-headline" level="2" weightLevel="4" color="grey-40">{{
          product.name
        }}</VueHeadline>
      </div>

      <div class="product-content-right">
        <div class="product-content-props">
          <img
            style="width: 75px; height: 123px; margin-left:10px"
            :src="getProductImage"
            :alt="product.name"
          />
          <div style="display: flex; align-items: center; margin: 0 30px">
            <div style="font-weight: 400; font-size: 14px; color: #79838e">
              <div style="display: flex; align-items: center; margin-bottom: 16px">
                <img src="~@/assets/icons/arrows-vertical.svg" />
                <span style="color: black; margin-left: 24px">{{ getUnitOfLength }}</span>
              </div>
              <div style="display: flex; align-items: center; margin-bottom: 16px">
                <img src="~@/assets/icons/envelope.svg" />
                <span style="color: black; margin-left: 20px">{{ getPackage }}</span>
              </div>
              <div style="display: flex; align-items: center">
                <img src="~@/assets/icons/radius.svg" />
                <span style="color: black; margin-left: 18px">{{ getRadius }}</span>
              </div>
            </div>
          </div>
          <div class="border-seperate"></div>
          <div>
            <div style="display: flex; justify-content: space-between; margin: 18px 10px">
              <div
                style="
                  margin-right: 20px;font-weight: 400;font-size: 12px;color: #79838e;display: flex;align-items: center;
                "
              >
                <img style="margin-right: 5px" src="~@/assets/icons/nikotin.svg" alt="nikotin" />

                Nikotin:
                <span style="color: black; margin-left: 5px">{{ productProps['NIKOTIN'] }} mg</span>
              </div>
              <div
                style="
                  font-weight: 400;
                  font-size: 12px;
                  color: #79838e;
                  display: flex;
                  align-items: center;
                "
              >
                <img style="margin-right: 5px" src="~@/assets/icons/zifir.svg" alt="zifir" />
                Zifir:
                <span style="color: black; margin-left: 5px">{{ productProps['ZIFIR'] }} mg</span>
              </div>
            </div>
            <img
              style="width: 310px"
              :style="setProductFilterDimension"
              :src="getBrandFiltreImage"
              alt="brand"
            />
            <div
              style="display: flex; justify-content: space-between; align-items: center; margin-left: 10px; margin-top: 20px ;"
            >
              <div
                style="
                  font-weight: 400;
                  font-size: 12px;
                  color: #79838e;
                  display: flex;
                  align-items: center;
                "
              >
                <img
                  style="margin-right: 5px"
                  src="~@/assets/icons/karbonmonoksit.svg"
                  alt="zifir"
                />
                Karbonmonoksit:
                <span style="color: black; margin-left: 5px"
                  >{{ productProps['KARBONMONOKSIT'] }} mg</span
                >
              </div>
              <div
                style="width:90px; text-align:center; font-weight: 400; font-size: 12px; color: #79838e; position: relative;"
              >
                <img
                  style="position: absolute; top:calc(50% - 24px); left:50%; transform: translate(-50%, -50%);"
                  src="~@/assets/icons/down-direction-arrow.svg"
                />
                <span style="color: black;">{{ getFilterType }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
    <a v-else>
      <img style="width: 100%" :src="getProductImage" :alt="product.name" />
    </a>
  </div>
</template>
<script>
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';

export default {
  name: 'BrandProductDetail',
  components: {
    VueHeadline,
  },
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      productProps: {},
    };
  },
  created() {
    if (!this.product.props) return;
    this.productProps = Object.assign({}, ...this.product.props.map(k => ({ [k.key]: k.value })));
  },
  computed: {
    getFilterType() {
      return this.productProps['FILTRE OZELLIK'];
    },
    getUnitOfLength() {
      return this.productProps['UZUNLUK'];
    },
    getPackage() {
      return this.productProps['PAKET TIPI'];
    },
    getRadius() {
      return this.productProps['CAP'];
    },
    getBrandFiltreImage() {
      return this.product.filterImageUrl;
    },
    getProductImage() {
      return this.product.images;
    },
  },
  methods: {
    setProductFilterDimension() {
      switch (this.productProps['CAP']) {
        case 'Standart Çap':
          return { height: '35px' };
        case 'İnce Çap':
          return { height: '20px' };
        case 'Ekstra İnce Çap':
          return { height: '25px' };
        default:
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.product {
  margin-bottom: palette-space-level('40');
  width: 100%;

  &-link {
    display: block;
    padding: 15px 0;
    border: 1px solid palette-color-level('grey', '20');
    border-radius: palette-radius-level('2');
  }
  &-content {
    display: flex;

    &-headline {
      margin-top: palette-space-level('10');
      font-weight: 500;
      font-size: 16px;
    }
    &-left {
      display: flex;
      align-items: center;
      margin-left: 30px;

      .brand-image {
        img {
          width: 75px;
          height: 123px;
        }
      }
    }
    &-props {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      justify-content: center;

      &-item {
        display: flex;
        align-items: center;
        padding: palette-space-level('3') 0;
        margin-right: palette-space-level('20');

        p:first-child {
          margin-right: palette-space-level('5');
        }
      }
    }
  }
}
.border-seperate {
  height: 1px;
  width: calc(100% + 30px);
  margin: palette-space-level('8') 0;
  background-color: palette-color-level('grey', '15');
}
</style>
