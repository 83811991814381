<template>
  <div id="divBrandDetailWrapper" v-if="brandData.brand" class="brand-detail">
    <img v-if="getBrandData.detailImg" :src="getBrandData.detailImg" class="brand-detail-banner" />
    <div v-if="getBrandData.name != 'Marlboro'" class="content-wrapper">
      <div class="landing">
        <div
          class="logo"
          :style="{
            backgroundPosition: 'center',
            backgroundSize: '80%',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${getBrandData.img}`,
          }"
        ></div>
        <div class="right-area">
          <VueHeadline level="3" weightLevel="6" color="grey-40" class="headline-text">{{
            getBrandData.name
          }}</VueHeadline>
          <VueText
            sizeLevel="3"
            color="grey-30"
            class="text"
            :class="{ 'text-toggled': isDescToggled }"
            v-html="getBrandData.desc"
          >
          </VueText>
          <div class="link-holder" v-if="getBrandData.desc">
            <VueButton ghost @click="toggleDesc">
              <VueText sizeLevel="3" weightLevel="3" class="left-link"
                >Daha {{ isDescToggled ? 'Az' : 'Fazla' }} Göster</VueText
              ></VueButton
            >
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; flex-flow: column; align-items: center;" v-else>
      <div class="swiper-container text-center">
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in brandHistory"
            :key="index"
            class="swiper-slide"
            @click="onBrandHistory(item)"
          >
            <div class="timestamp" />
            <div :class="[currentBrandDetail.id == item.id ? 'status-after' : 'status']">
              <div
                style="display: flex; flex-direction: column; align-items: center; margin-top: 25px; cursor: pointer"
              >
                <img
                  style="margin-bottom: 20px; width: 60px; height: 90px"
                  :src="item.imageUrl"
                  alt="brand"
                />
                <span
                  :id="`brand-date + ${index}`"
                  style="font-size: 16px; font-weight: 500;"
                  :class="[currentBrandDetail.id == item.id ? 'selected-date' : '']"
                  >{{ item.date }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; margin: 5%">
        <div>
          <ul v-for="(bullet, i) in getBullets" :key="i">
            <li style="margin: 25px 5px 0 5px; font-weight: 400; font-size: 14px">{{ bullet }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content-wrapper">
      <VueHeadline class="products-headline" level="3" weightLevel="3">Ürünler</VueHeadline>
      <div class="products-wrapper">
        <BrandProductDetail
          v-for="product in brandData.products"
          :key="product.productId"
          :product="product"
        ></BrandProductDetail>
      </div>
    </div>
    <div class="content-wrapper grey-bg">
      <VueHeadline class="brand-headline" level="3" weightLevel="3">Marka Haberleri</VueHeadline>
      <BrandList :brandItems="news" @nextpage="getContents(true)"></BrandList>
      <div class="show-more-wrapper">
        <div class="show-more" @click="showMore" v-if="getMoreNews">Daha fazla gör</div>
      </div>
    </div>
  </div>
</template>
<script>
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandList from '@/components/brand/BrandList/BrandList.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import BrandProductDetail from '@/components/brand/BrandProduct/BrandProductDetail.vue';
import StorageProps from '@/mixins/storageProps.js';
import { Product, Content } from '@/services/Api/index';
import ContentApi from '@/services/Api/content';
import { maxBy } from '@/mixins/arrayUtils.js';
export default {
  name: 'PMIBrandDetail',
  components: {
    VueButton,
    VueText,
    VueHeadline,
    BrandProductDetail,
    BrandList,
  },
  mixins: [StorageProps],
  data() {
    return {
      brandData: {},
      getMoreNews: false,
      isDescToggled: false,
      news: [],
      lastIndex: 0,
      lastId: 0,
      pageSize: 2,
      brandHistory: [],
      currentBrandDetail: null,
      otherIds: [],
    };
  },
  created() {
    this.getContents();
  },
  mounted() {
    ContentApi.getBrandHistory({ BrandName: 'Marlboro' }).then(res => {
      if (res?.data) {
        this.brandHistory = res.data.Data.map((element, index) => {
          return { ...element, id: index };
        });

        this.currentBrandDetail = this.brandHistory[0];
      }
    });
    Product.getBrandById(this.$route.params.id).then(res => {
      const {
        Data: { brandDetail },
      } = res.data;
      this.brandData = brandDetail;
      if (brandDetail && brandDetail.brand.name) {
        this.setAppBarTitle(brandDetail.brand.name);
      }
    });
  },
  computed: {
    getBrandData() {
      let name,
        desc,
        img = '',
        detailImg = '';
      name = this.brandData.brand.name;
      desc = this.brandData.content;
      img = this.brandData.brand.image;
      detailImg = this.brandData.backgroundImage;

      return { name, desc, img, detailImg };
    },
    getBullets() {
      return this.currentBrandDetail?.bullets ?? [];
    },
  },
  methods: {
    onBrandHistory(brand) {
      this.currentBrandDetail = brand;
    },
    toggleDesc() {
      this.isDescToggled = !this.isDescToggled;
    },
    showMore() {
      this.lastIndex = this.news.length;
      this.lastId = maxBy(this.news, 'feedId');
      this.pageSize = 10;
      this.getContents(this.getMoreNews);
    },
    getContents(isNextPage) {
      const brandId = this.$route.params.id;
      Content.getBrandNews(this.lastId, this.lastIndex, this.pageSize, brandId).then(res => {
        const {
          Data: { contents },
        } = res.data;

        if (!contents || contents.length < this.pageSize) {
          this.getMoreNews = false;
        }

        if (isNextPage) {
          this.news.push(...contents);
        } else {
          this.news = contents;
        }
        if (this.news.length) {
          this.lastIndex = this.news.length;
          this.lastId = this.news[this.lastIndex - 1].feedId;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.landing {
  display: flex;

  .logo {
    background-color: palette-color-level('white', '100');
    min-width: 60px;
    height: 60px;
    border: 1px solid palette-color-level('grey', '20');
    border-radius: palette-radius-level('1');
  }

  .right-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 18px;

    .headline-text {
      margin-bottom: palette-space-level(10);
    }
    .text {
      position: relative;
      line-height: 1.27;
      padding-right: palette-space-level(10);
      max-height: 30px;
      overflow: hidden;
      max-width: 100%;

      &:after {
        content: '...';
        position: absolute;
        background: #fff;
        right: 11px;
        bottom: 0;
      }
      &-toggled {
        max-height: unset;

        &:after {
          display: none;
        }
      }
    }

    .link-holder {
      display: flex;
      flex-direction: row;
      align-items: space-between;

      .left-link {
        padding-right: palette-space-level(30);
      }
      .left-link,
      .right-link {
        padding-top: palette-space-level(10);
        line-height: 1.27;
        text-decoration: underline;
      }
    }
  }
}
.grey-bg {
  background-color: palette-color-level('grey', '10');
  text-align: center;
}
.content-wrapper {
  padding: palette-space-level('20');
}
.products-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.products-headline {
  padding: palette-space-level('10') 0;
}
.brand-headline {
  padding: palette-space-level('10') 0;
  text-align: left;
}
.brand-detail-banner {
  display: block;
  max-width: 100%;
  margin: auto;
}
.show-more-wrapper {
  display: inline-block;
  width: 100%;
  .show-more {
    width: 100%;
    max-width: 140px;
    height: 45px;
    line-height: 45px;
    border-radius: palette-radius-level('15');
    border: solid 2px palette-color-level('grey', '20');
    background-color: palette-color-level('white', '100');
    text-align: center;
    font-size: palette-font-size-level('4');
    font-weight: palette-font-weight-level('3');
    color: palette-color-level('grey', '40');
    margin: 0 auto;
  }
}

.timestamp {
  margin: auto;
  margin-bottom: 5px;
  padding: 0px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status {
  padding: 10px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #b4c2d3;
  position: relative;
  transition: all 200ms ease-in;
}
.status span {
  padding-top: 8px;
}
.status span:before {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #b4c2d3;
  border-radius: 12px;
  border: 2px solid #b4c2d3;
  position: absolute;
  left: 50%;
  top: 0%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 200ms ease-in;
}

.status-after {
  padding: 10px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #b4c2d3;
  position: relative;
  transition: all 200ms ease-in;
}
.status-after span {
  padding-top: 8px;
}
.status-after span:before {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #cc1c4e;
  border-radius: 12px;
  border: 2px solid #cc1c4e;
  position: absolute;
  left: 50%;
  top: 0%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 200ms ease-in;
}
.swiper-container {
  width: 95%;
  margin: auto;
  padding: 50px 30px 0px 30px;
  overflow-y: auto;
}
.swiper-wrapper {
  display: inline-flex;
  flex-direction: row;
  overflow-y: auto;
  justify-content: center;
}
.swiper-container::-webkit-scrollbar-track {
  background: #a8a8a8b6;
}
.swiper-container::-webkit-scrollbar {
  height: 2px;
}
.swiper-container::-webkit-scrollbar-thumb {
  background: #4f4f4f !important;
}
.swiper-slide {
  text-align: center;
  font-size: 12px;
  width: 200px;
  height: 100%;
  position: relative;
}
.selected-date {
  color: #cc1c4e;
}

.selected-time {
  padding: 10px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #b4c2d3;
  position: relative;
  transition: all 200ms ease-in;
}
.selected-time span {
  padding-top: 8px;
}
</style>
